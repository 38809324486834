import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'


const SatSchedule = () => ( {
    render() {
        return (
            <Container>
                <h3 className="text-white mb-4">DAY PROGRAMMING</h3>
                <Row className="justify-content-center">
                <Col className="justify-content-center col-sm-3 mb-5">
                        <h3 className="">@NIGHTLIGHT</h3>
                        <div className="col-m-5">
                            <h4>2:00pm - 7:00pm</h4>
                            <div className="lineup-header">
                                <p>INSIDE :: live sets</p>
                            </div>
                            <div className="lineup-body">
                                <p>Cienfuegos (Miami/NYC)</p>
                                <p>Role Play (NYC)</p>
                                <p>Hanz</p>
                                <p>Hook Up (Asheville)</p>
                                <p>Pothos Traxx</p>
                                <p>Waveshaper (Charlotte)</p>
                            </div>
                            <div className="lineup-header">
                                <p>PATIO :: dj sets</p>
                            </div>
                            <div className="lineup-body">
                                <p>WXYC djs</p>
                                <p>(4:00pm - 7:00pm)</p>
                                <p>djs tba</p>
                                <p>WXDU / FDDF djs</p>
                                <p>(2:00pm - 4:00pm)</p>
                                <p>Ace E</p>
                                <p>MATTT</p>
                            </div>
                        </div>

                    </Col>
                    <Col className="justify-content-center col-sm-4 mb-5">
                        <h3 className="">@THE ARTSCENTER</h3>
                        <div className="col-m-5">
                            <h4>1:00pm - 6:00pm</h4>
                            <div className="lineup-header">
                                <p>Wicked Witch &amp; 919Noise family live sets</p>
                            </div>
                            <div className="lineup-body">
                                <p>Copula</p>
                                <p>Rescinder</p>
                                <p>De_Plata</p>
                                <p>Bryce Eiman</p>
                                <p>more tba</p>
                            </div>
                        </div>

                    </Col>

                </Row>
                <h3 className="text-white mt-5 mb-4">EVENING PROGRAMMING</h3>
                <Row className="justify-content-center">
                    <Col className="justify-content-center col-sm-3 mb-5">
                        <h3 className="">@NIGHTLIGHT</h3>
                        <div className="col-m-5">
                            <h4>9:00pm - 2:00am</h4>
                            <div className="lineup-header">
                                <p>RFYG Main Dance</p>
                                <p>presented by Nightlight &amp; Volume</p>
                                <p>(inside and patio w/ video feed)</p>
                            </div>
                            <div className="lineup-body">
                                <p>Claude Young Jr (Detroit-3 hour dj set)</p>
                                <p>Mike D</p>
                                <p>Chooch</p>
                            </div>
                        </div>
                    </Col>
                    <Col className="justify-content-center col-sm-3 mb-5">
                        <h3 className="">@NORTHSIDE</h3>
                        <div className="col-m-5">
                            <h4>9:00pm - 2:00am</h4>
                            <div className="lineup-header">
                            <p className="text-white" >dj sets</p>
                            </div>
                            <div className="lineup-body">
                                <p>Luxe Posh</p>
                                <p>DJKB</p>
                                <p>Fifi Hi-Fi</p>
                                <p>One Duran</p>
                            </div>

                        </div>

                    </Col>
                </Row>

            </Container>
        )
    }
})

export default SatSchedule
