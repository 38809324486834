import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

const FriSchedule = () => ( {
    render() {
        return (
            <Container>
            <Row className="justify-content-center">
                <Col className="justify-content-center col-sm-3 mb-5">
                    <h3 className="">@NIGHTLIGHT</h3>
                    <div className="col-m-5">
                        <h4>7:00pm - 2:00am</h4>
                        <div className="lineup-header">
                        <p>INSIDE</p>
                        </div>
                        <div className="lineup-body">
                            <p>Funke (New Orleans)</p>
                            <p>Lady Fingers</p>
                            <p>Vespertine</p>
                            <p>Monsier</p>
                            <p>Chocolate Rice</p>
                            <p>boxofbox</p>
                        </div>
                        <div className="lineup-header">
                            <p>PATIO :: party showcase</p>
                        </div>
                        <div className="lineup-body">
                            <p>:: becoming with feat. Gudiya and Pamela_and her sons (live)</p>
                            <p>:: Mamis &amp; Papis feat. Ma'Duro x Uymami</p>
                            <p>:: Party Illegal feat. Queen Plz, Tree City, PlayPlay, GRRL with Jasmyn Milan x Khvox</p>
                            <p>:: The Floor (b2b - djs tba)</p>
                            <p>:: Fauna feat. Kir x K2Dwave</p>
                            <p>:: Raundhaus feat. Ronnie Flash(live)</p>
                        </div>
                    </div>

                </Col>
                <Col className="justify-content-center col-sm-3 mb-5">
                    <h3 className="">@LOCAL 506</h3>
                    <div className="col-m-5">
                        <h4>7:00pm - 1:00am</h4>
                        <div className="lineup-header">
                        <p className="text-white" >Hot Releases presents</p>
                        </div>
                        <div className="lineup-body">
                            <p>Binky (Durham/Providence)</p>
                            <p>Aaron Dilloway (Oberlin, OH)</p>
                            <p>Housefire</p>
                            <p>Tether (Philadelphia)</p>
                            <p>Floor Model</p>
                            <p>Patrick Gallagher (Philadelphia)</p>
                            <p>Secret Boyfriend</p>
                            <p>Governance</p>
                        </div>

                    </div>

                </Col>
                <Col className="justify-content-center col-sm-3 mb-5">
                    <h3 className="">@NORTHSIDE</h3>
                    <div className="col-m-5">
                        <h4>9:00pm - 2:00am</h4>
                        <div className="lineup-header">
                        <p className="text-white" >dj sets</p>
                        </div>
                        <div className="lineup-body">
                            <p>k.ward</p>
                            <p>K ROX</p>
                            <p>Strictly Social (Greensboro)</p>
                            <p>^djs tba</p>
                        </div>

                    </div>

                </Col>
            </Row>

        </Container>
        )
    }
})

export default FriSchedule
