import React, { useState, setState } from "react"
import { Fade, Container, Row, Col, Button } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"

import ThursSchedule from "../components/thurs-schedule"
import FriSchedule from "../components/fri-schedule"
import SatSchedule from "../components/sat-schedule"
import SunSchedule from "../components/sun-schedule"


const SchedulePage = () => {
    const [schedule, setSchedule] = useState("THURS");

    return (
        <Layout pageInfo={{ pageName: "schedule", }}>
            <SEO title="Schedule" />

            <Container>
                <Row className="justify-content-center">
                    <Col xs={3} className="text-center col-sm-2" onClick={() => setSchedule("THURS")}>
                        <Button className="schedule-btn">THURS Oct 8*</Button>
                    </Col>
                    <Col xs={3} className="text-center col-sm-2" onClick={() => setSchedule("FRI")}>
                        <Button className="schedule-btn">FRI Oct 9*</Button>
                    </Col >
                    <Col xs={3} className="text-center col-sm-2">
                        <Button className="schedule-btn" onClick={() => setSchedule("SAT")}>SAT Oct 10*</Button>
                    </Col>
                    <Col xs={3} className="text-center col-sm-2">
                        <Button className="schedule-btn" onClick={() => setSchedule("SUN")}>SUN Oct 11*</Button>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    {schedule === "THURS" && <ThursSchedule></ThursSchedule>}
                    {schedule === "FRI" && <FriSchedule></FriSchedule>}
                    {schedule === "SAT" && <SatSchedule></SatSchedule>}
                    {schedule === "SUN" && <SunSchedule></SunSchedule>}
                </Row>

            </Container>
            <h5 className="text-center"><em>* Dates and lineup may change. Thank you for your understanding.</em></h5>

        </Layout>

    )
}


export default SchedulePage