import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

const ThursSchedule = () => ({
    render() {
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col className="justify-content-center col-sm-3 mb-5">
                        <h3 className="">@NIGHTLIGHT</h3>
                        <div className="col-m-5">
                            <h4>9:30pm - 2:00am</h4>
                            <div className="lineup-header">
                            <p className="text-white" >NO VISA Special Edition</p>
                            <p className="text-white">presented by Immaculate Taste</p>
                            </div>
                            <div className="lineup-body">
                                <p>Kampire (Uganda)</p>
                                <p>Alec Lomami (DR Congo)</p>
                                <p>Aldo Gibran (Mexico/USA</p>
                                <p>WELL$ (DR Congo/USA)</p>
                            </div>
                        </div>

                    </Col>
                    <Col className="justify-content-center col-sm-3 mb-5">
                        <h3 className="">@NORTHSIDE</h3>
                        <div className="col-m-5">
                            <h4>9:00pm - 2:00am</h4>
                            <div className="lineup-header">
                            <p className="text-white" >RFYG Kickoff</p>
                            <p className="text-white" >presented by Disco Sweat</p>
                            </div>
                            <div className="lineup-body">
                            <p className="text-white">Karina</p>
                            <p className="text-white">Magical Body</p>
                            <p className="text-white">Misty Touch</p>
                            <p className="text-white">E Main</p>
                            <p className="text-white">ZR DJ</p>
                            </div>

                        </div>

                    </Col>
                </Row>

            </Container>
        )
    }
})

export default ThursSchedule


const lineupHeaderStyles = {
    color: 'white',
    marginTop: '2rem'
}
