import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'


const SunSchedule = () => ({
    render() {
        return (
            <Container>
                <h3 className="text-white">RFYG Hangover</h3>
                <Row className="justify-content-center">

                    <Col className="justify-content-center col-m-4 mb-5">
                        <div className="">
                            <h3>@NIGHTLIGHT</h3>
                            <h4>2:00pm - 10:00pm</h4>
                            <div className="lineup-header">
                                <h5>presented by Activ-Analog, Abstracta Audio, Tone Log, and All Day Records</h5>
                                <h5>food by Aaron Smithers ($5 plate)</h5>
                            </div>

                        </div>

                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className="justify-content-center col-sm-3 mb-5">
                        <div className="lineup-header">
                            <p>INSIDE :: live sets</p>
                        </div>
                        <div className="lineup-body">
                            <p>Shawn Rudiman (Pittsburgh)</p>
                            <p>Loner.9 (Winston-Salem)</p>
                            <p>Glyn Maier (South Oxford, ME)</p>
                            <p>Heart Pressure (L.A.)</p>
                            <p>Dro Idol (Charlotte)</p>
                            <p>Stinkworx</p>
                            <p>MARV</p>
                            <p>Cevra</p>
                        </div>
                    </Col>
                    <Col className="justify-content-center col-sm-3 mb-5">
                        <div className="lineup-header">
                            <p>PATIO :: dj sets</p>
                        </div>
                        <div className="lineup-body">
                            <p>Pete Linck</p>
                            <p>Mr.White (Memphis - dj &amp; vocal performance)</p>
                            <p>Rheji Burrell (NYC/Raleigh)</p>
                            <p>Party Dad (Charlotte)</p>
                            <p>Femi the Femme</p>
                            <p>Longchild (Charlotte)</p>
                            <p>Karina</p>
                        </div>

                    </Col>

                </Row>

            </Container>
        )
    }
})

export default SunSchedule
